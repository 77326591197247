import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import type { IAuthInput } from '../../global';
import { setIsSoundOn } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { getSlotLoadProgressInfoGql, slotConfigGql } from '../../gql/query';
import { parseQuery } from '../../utils';
import LoadScreen from '../LoadScreen/loadScreen';
import SlotScreen from '../SlotScreen/slotScreen';

const App: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  useReactiveVar(setIsSoundOn);
  const { data: slotLoadProgressInfo } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getSlotLoadProgressInfoGql);
  const { progress: slotLoadProgress } = slotLoadProgressInfo!;
  const { i18n } = useTranslation();
  const isGameLoaded = slotLoadProgress?.status >= 100;

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return <>{slotConfig?.isAuthorized && isGameLoaded ? <SlotScreen /> : <LoadScreen />}</>;
};

export default App;
