import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { config, ISongs } from '../../../config';
import { PopupOpeningTypes } from '../../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsPopupOpeningInProgress,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceDecreaseSliderValue,
  setStopOnBalanceIncrease,
  setStopOnBalanceIncreaseSliderValue,
  setStopOnWinExceeds,
  setStopOnWinExceedsSliderValue,
  setUserBalance,
} from '../../../gql/cache';
import type { ISlotConfig } from '../../../gql/d';
import { getAutoSpinsSettingsGql, getBetAmountGql } from '../../../gql/query';
import { normalizeCoins, showCurrency } from '../../../utils';
import Button from '../components/button';
import RangeSlider from '../components/rangeSlider';
import Toggle from '../components/toggle';
import styles from './autoPlaySettings.module.scss';
import type { IAutoPlaySettingsProps } from './d';

const AutoPlaySettings: React.FC<IAutoPlaySettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '' } = props;
  const {
    clientSettings: { autoplay },
  } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const [isAdvanced, setIsAdvanced] = useState(false);

  const { data } = useQuery<{
    autoSpinsAmount: number;
    isStopOnAnyWin: boolean;
    isStopOnFeatureWin: boolean;
    isStopOnWinExceeds: boolean;
    isStopOnBalanceIncrease: boolean;
    isStopOnBalanceDecrease: boolean;
    stopOnWinExceeds: number;
    stopOnBalanceIncrease: number;
    stopOnBalanceDecrease: number;
  }>(getAutoSpinsSettingsGql);
  useEffect(() => {
    setAutoSpinsAmount(config.autoplay.autoSpinValues[3]);
  }, []);

  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);
  // TODO to be removed
  const currency = userBalance.balance.currency || 'FUN';
  const balanceAmount = userBalance.balance.amount;
  const { betAmount } = dataBet!;
  const { autoSpinsAmount, isStopOnFeatureWin, stopOnWinExceeds, stopOnBalanceIncrease, stopOnBalanceDecrease } = data!;

  const winExceedsSliderValue = useReactiveVar<number>(setStopOnWinExceedsSliderValue);
  const balanceIncreaseSliderValue = useReactiveVar<number>(setStopOnBalanceIncreaseSliderValue);
  const balanceDecreaseSliderValue = useReactiveVar<number>(setStopOnBalanceDecreaseSliderValue);
  const onAutoSpinsAmountChange = useCallback((value: number) => {
    setAutoSpinsAmount(value);
  }, []);

  const coinMultiplier = setSlotConfig().lineSets[0]!.coinAmountMultiplier;

  const handleStartAutoplay = useCallback(() => {
    if (setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP) {
      AudioApi.play({ type: ISongs.UI_AutoSpin });
      setLastRegularWinAmount(0);
      setAutoSpinsLeft(autoSpinsAmount);
      setIsAutoSpins(true);
      setAutoSpinsStartBalance(balanceAmount);
      setIsOpenAutoplayPopup(false);
    }
  }, [balanceAmount, autoSpinsAmount]);

  useEffect(() => {
    if (
      autoplay.conditions.stopIfSingleWinExceeds.multipliers[winExceedsSliderValue - 1]! * betAmount !==
      stopOnWinExceeds
    ) {
      setStopOnWinExceeds(betAmount);
      setStopOnWinExceedsSliderValue(0);
    }
    if (
      autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[balanceIncreaseSliderValue - 1]! * betAmount !==
      stopOnBalanceIncrease
    ) {
      setStopOnBalanceIncrease(betAmount);
      setStopOnBalanceIncreaseSliderValue(0);
      setStopOnBalanceDecrease(betAmount);
      setStopOnBalanceDecreaseSliderValue(0);
    }
  }, []);

  const handleHover = () => {
    if (isMobile || AudioApi.isPlaying(ISongs.UI_ButtonPress)) return;
    AudioApi.play({ type: ISongs.UI_ButtonHover });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['wrap']}>
        <div className={`${styles['autoplay-settings']} ${className}`}>
          <div className={styles['autoplay-settings__col']}>
            <div className={styles.title}>
              {!isAdvanced ? <span>{t('autoPlay')}</span> : <span>{t('autoPlayAdvanced')}</span>}
            </div>
          </div>
          <div className={styles['autoplay-settings__col']}>
            {!isAdvanced ? (
              <div className={`${styles['autoplay-settings__item']} ${styles['btns']}`}>
                <div className="btn-group">
                  {(autoplay.options as number[]).map((item) => (
                    <Button
                      className="uiBtn"
                      intent="autoPlaySettingBtn"
                      key={item}
                      isActive={autoSpinsAmount === item}
                      title={`${item}`}
                      onClick={() => {
                        AudioApi.play({ type: ISongs.UI_BetChange });
                        onAutoSpinsAmountChange(item);
                      }}
                      onMouseOver={handleHover}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <>
                {autoplay.conditions.stopIfFeatureIsWon.enabled && (
                  <div className={`${styles['autoplay-settings__item']} ${styles['checkbox']}`}>
                    <Toggle
                      className={styles['toggle'] as string}
                      checked={isStopOnFeatureWin}
                      id="stop_on_feature_win"
                      onChange={() => {
                        setIsStopOnFeatureWin(!isStopOnFeatureWin);
                        AudioApi.play({ type: ISongs.UI_ButtonPress });
                      }}
                    />
                    <div className={styles['label']}>{t('autoPlayStopOnFeatureWin')}</div>
                  </div>
                )}
                {autoplay.conditions.stopIfBalanceIncreasesBy.enabled && (
                  <div className={styles['autoplay-settings__item']}>
                    <div className={styles['label']}>
                      {t('autoPlayStopBalanceUp')}
                      <span className={styles['value']}>
                        {balanceIncreaseSliderValue ? (
                          `${formatNumber({
                            currency: currency,
                            value: normalizeCoins(stopOnBalanceIncrease),
                            showCurrency: showCurrency(currency),
                          })}`
                        ) : (
                          <span className={styles['off']}>OFF</span>
                        )}
                      </span>
                    </div>
                    <RangeSlider
                      isActive={!!balanceIncreaseSliderValue}
                      className={styles['slider']}
                      min={0}
                      max={autoplay.conditions.stopIfBalanceIncreasesBy.multipliers.length - 1}
                      value={balanceIncreaseSliderValue}
                      onChange={(v) => {
                        setIsStopOnBalanceIncrease(!!v);
                        setStopOnWinExceedsSliderValue(v);
                        setStopOnBalanceIncreaseSliderValue(v);
                        setStopOnBalanceIncrease(
                          setCoinAmount() *
                            autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[v]! *
                            coinMultiplier,
                        );
                        AudioApi.play({ type: ISongs.UI_BetChange });
                      }}
                    />
                  </div>
                )}
                {autoplay.conditions.stopIfBalanceDecreasesBy.enabled && (
                  <div className={styles['autoplay-settings__item']}>
                    <div className={styles['label']}>
                      {t('autoPlayStopBalanceDown')}
                      <span className={styles['value']}>
                        {balanceDecreaseSliderValue ? (
                          `${formatNumber({
                            currency: currency,
                            value: normalizeCoins(stopOnBalanceDecrease),
                            showCurrency: showCurrency(currency),
                          })}`
                        ) : (
                          <span className={styles['off']}>OFF</span>
                        )}
                      </span>
                    </div>
                    <RangeSlider
                      isActive={!!balanceDecreaseSliderValue}
                      className={styles['slider'] as string}
                      min={0}
                      max={balanceAmount / setCoinValue()}
                      value={balanceDecreaseSliderValue}
                      onChange={(v) => {
                        setIsStopOnBalanceDecrease(!!v);
                        setStopOnBalanceDecrease(v);
                        setStopOnBalanceDecreaseSliderValue(v);
                        AudioApi.play({ type: ISongs.UI_BetChange });
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles['autoplay-settings__col']}>
            <span style={{ display: `${!isAdvanced ? 'block' : 'none'}` }}>
              <Button
                className={styles.btn}
                intent="settings"
                onClick={() => {
                  AudioApi.play({ type: ISongs.UI_ButtonPress });
                  setIsAdvanced(!isAdvanced);
                }}
                onMouseOver={handleHover}
              />
            </span>
            <span style={{ display: `${!isAdvanced ? 'none' : 'block'}` }}>
              <Button
                className={styles.btn}
                intent="back"
                onClick={() => {
                  AudioApi.play({ type: ISongs.UI_ButtonPress });
                  setIsAdvanced(!isAdvanced);
                }}
                onMouseOver={handleHover}
              />
            </span>
            <Button
              className={styles.btn}
              intent="confirm"
              onClick={handleStartAutoplay}
              disabled={autoSpinsAmount === 0}
              onMouseOver={handleHover}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoPlaySettings;
