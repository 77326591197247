import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';
import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.high1 },
  [SlotId.B]: { default: ResourceTypes.high2 },
  [SlotId.C]: { default: ResourceTypes.high4 },
  [SlotId.D]: { default: ResourceTypes.high5 },
  [SlotId.E]: { default: ResourceTypes.high3 },
  [SlotId.F]: { default: ResourceTypes.low5 },
  [SlotId.G]: { default: ResourceTypes.low3 },
  [SlotId.H]: { default: ResourceTypes.low4 },
  [SlotId.I]: { default: ResourceTypes.low1 },
  [SlotId.J]: { default: ResourceTypes.low2 },
  [SlotId.SC1]: { default: ResourceTypes.scatter },
  [SlotId.SC]: { default: ResourceTypes.scatter },
  [SlotId.WL]: { default: ResourceTypes.wild },
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: { default: ResourceTypes.high1Motion },
  [SlotId.B]: { default: ResourceTypes.high2Motion },
  [SlotId.C]: { default: ResourceTypes.high4Motion },
  [SlotId.D]: { default: ResourceTypes.high5Motion },
  [SlotId.E]: { default: ResourceTypes.high3Motion },
  [SlotId.F]: { default: ResourceTypes.low5Motion },
  [SlotId.G]: { default: ResourceTypes.low3Motion },
  [SlotId.H]: { default: ResourceTypes.low4Motion },
  [SlotId.I]: { default: ResourceTypes.low1Motion },
  [SlotId.J]: { default: ResourceTypes.low2Motion },
  [SlotId.SC1]: { default: ResourceTypes.scatterMotion },
  [SlotId.SC]: { default: ResourceTypes.scatterMotion },
  [SlotId.WL]: { default: ResourceTypes.wildMotion },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'HP_Sevens_Win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'HP_Bell_Win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'HP_Pineapple_Win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'HP_Strawberry_Win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'HP_Orange_Win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'LP_Watermelon_Win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'LP_Banana_Win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'LP_Cherries_Win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'LP_Blueberry_Win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'LP_Plum_Win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Wild_Win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Scatter_Win',
  },
  [SlotId.SC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Scatter_Win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Wild_Land',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Scatter_Land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.low1, src: Resources.getSource(ResourceTypes.low1) },
  {
    name: ResourceTypes.low2,
    src: Resources.getSource(ResourceTypes.low2),
  },
  {
    name: ResourceTypes.low3,
    src: Resources.getSource(ResourceTypes.low3),
  },
  { name: ResourceTypes.low4, src: Resources.getSource(ResourceTypes.low4) },
  {
    name: ResourceTypes.low5,
    src: Resources.getSource(ResourceTypes.low5),
  },
  {
    name: ResourceTypes.high1,
    src: Resources.getSource(ResourceTypes.high1),
  },
  {
    name: ResourceTypes.high2,
    src: Resources.getSource(ResourceTypes.high2),
  },
  {
    name: ResourceTypes.high3,
    src: Resources.getSource(ResourceTypes.high3),
  },
  {
    name: ResourceTypes.high4,
    src: Resources.getSource(ResourceTypes.high4),
  },
  {
    name: ResourceTypes.high5,
    src: Resources.getSource(ResourceTypes.high5),
  },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
  {
    name: ResourceTypes.wild,
    src: Resources.getSource(ResourceTypes.wild),
  },
  { name: ResourceTypes.low1Motion, src: Resources.getSource(ResourceTypes.low1Motion) },
  {
    name: ResourceTypes.low2Motion,
    src: Resources.getSource(ResourceTypes.low2Motion),
  },
  {
    name: ResourceTypes.low3Motion,
    src: Resources.getSource(ResourceTypes.low3Motion),
  },
  { name: ResourceTypes.low4Motion, src: Resources.getSource(ResourceTypes.low4Motion) },
  {
    name: ResourceTypes.low5Motion,
    src: Resources.getSource(ResourceTypes.low5Motion),
  },
  {
    name: ResourceTypes.high1Motion,
    src: Resources.getSource(ResourceTypes.high1Motion),
  },
  {
    name: ResourceTypes.high2Motion,
    src: Resources.getSource(ResourceTypes.high2Motion),
  },
  {
    name: ResourceTypes.high3Motion,
    src: Resources.getSource(ResourceTypes.high3Motion),
  },
  {
    name: ResourceTypes.high4Motion,
    src: Resources.getSource(ResourceTypes.high4Motion),
  },
  {
    name: ResourceTypes.high5Motion,
    src: Resources.getSource(ResourceTypes.high5Motion),
  },
  {
    name: ResourceTypes.scatterMotion,
    src: Resources.getSource(ResourceTypes.scatterMotion),
  },
  {
    name: ResourceTypes.wildMotion,
    src: Resources.getSource(ResourceTypes.wildMotion),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.betBackground, src: Resources.getSource(ResourceTypes.betBackground) },
  { name: ResourceTypes.betMinusBtn, src: Resources.getSource(ResourceTypes.betMinusBtn) },
  { name: ResourceTypes.betMinusBtnDisable, src: Resources.getSource(ResourceTypes.betMinusBtnDisable) },
  { name: ResourceTypes.betMinusBtnHover, src: Resources.getSource(ResourceTypes.betMinusBtnHover) },
  { name: ResourceTypes.betMinusBtnPressed, src: Resources.getSource(ResourceTypes.betMinusBtnPressed) },
  { name: ResourceTypes.betPlusBtn, src: Resources.getSource(ResourceTypes.betPlusBtn) },
  { name: ResourceTypes.betPlusBtnDisable, src: Resources.getSource(ResourceTypes.betPlusBtnDisable) },
  { name: ResourceTypes.betPlusBtnHover, src: Resources.getSource(ResourceTypes.betPlusBtnHover) },
  { name: ResourceTypes.betPlusBtnPressed, src: Resources.getSource(ResourceTypes.betPlusBtnPressed) },

  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: 'symbols',
    src: '/animations/symbols/symbols.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/win_popups.json',
  },
  {
    name: 'spritesheet',
    src: '/images/ui/spritesheet.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/BG_Fruit.json',
  },
  {
    name: 'gameLogo',
    src: '/animations/logo/logo.json',
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
];
