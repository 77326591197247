import { Spine } from 'pixi-spine';
import { Loader, Sprite, Text, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCurrency, setIsDuringBigWinLoop } from '../../gql/cache';
import { Logic } from '../../logic';
import { getBGMSoundByGameMode, getWinStage, normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BigWinTextValueTextStyle,
  BigWinTextValueTextStyle2,
  eventManager,
  GAME_FPS,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  SUPER_WIN_AMOUNT_LIMIT,
  SUPER_WIN_COUNT_UP_MULTIPLIER,
  ULTRA_WIN_COUNT_UP_MULTIPLIER,
  WinStages,
} from '../config';

export class BigWinContainer extends ViewContainer {
  protected background: Sprite;

  private spine: Spine;

  private winValue = new Text();

  private winValue2 = new Text();

  private bigWinCountUpAnimation: Animation | null = null;

  private isLandscape = true;

  constructor() {
    super();
    this.spine = new Spine(Loader.shared.resources['bigWinMessages']!.spineData!);
    this.background = this.initBackground();
    this.addChild(this.background);
    this.addChild(this.spine);
    this.winValue.addChild(this.winValue2);
    this.spine.skeleton.findSlot('place_holder').currentSprite.texture = Texture.EMPTY;
    this.spine.skeleton.findSlot('place_holder').currentSprite.addChild(this.winValue);
    this.zIndex = 2;
    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, this.startBigWinPresentation.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.7;
    sprite.visible = false;
    return sprite;
  }

  private startBigWin(): void {
    const inAnimation = this.isLandscape ? 'BigWin_Intro' : 'BigWin_Intro';
    const loopAnimation = this.isLandscape ? 'BigWin_Cycle' : 'BigWin_Cycle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endBigWin(): void {
    const outAnimation = this.isLandscape ? 'BigWin_Outro' : 'BigWin_Outro';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startMegaWin(): void {
    const inAnimation = this.isLandscape ? 'Big_to_MegaWin' : 'Big_to_MegaWin';
    const loopAnimation = this.isLandscape ? 'MegaWin_Cycle' : 'MegaWin_Cycle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endMegaWin(): void {
    const outAnimation = this.isLandscape ? 'MegaWin_Outro' : 'MegaWin_Outro';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startSuperWin(): void {
    const inAnimation = this.isLandscape ? 'Mega_to_SuperWin' : 'Mega_to_SuperWin';
    const loopAnimation = this.isLandscape ? 'SuperWin_Cycle' : 'SuperWin_Cycle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endSuperWin(): void {
    const outAnimation = this.isLandscape ? 'SuperWin_Outro' : 'SuperWin_Outro';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startUltraWin(): void {
    const inAnimation = this.isLandscape ? 'Super_to_UltraWin' : 'Super_to_UltraWin';
    const loopAnimation = this.isLandscape ? 'UltraWin_Cycle' : 'UltraWin_Cycle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endUltraWin(): void {
    const outAnimation = this.isLandscape ? 'UltraWin_Outro' : 'UltraWin_Outro';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private skipWinCountUpAnimation() {
    this.bigWinCountUpAnimation?.skip();
  }

  public startBigWinPresentation(winAmount: number): void {
    this.setWinValue(0);
    this.winValue.alpha = 1;
    const betAmount = normalizeCoins(setBetAmount());
    const normalizedWinAmount = normalizeCoins(winAmount);
    const stage = getWinStage(winAmount);
    setIsDuringBigWinLoop(true);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    animationChain.addOnStart(() => {
      this.background.visible = true;
      eventManager.emit(EventTypes.SHOW_COINS);
    });
    animationChain.addOnSkip(() => {
      this.bigWinCountUpAnimation = null;
      eventManager.emit(EventTypes.HIDE_COINS);
    });
    animationChain.addOnComplete(() => {
      this.bigWinCountUpAnimation = null;
      eventManager.emit(EventTypes.HIDE_COINS);
    });
    if (stage >= WinStages.BigWin) {
      const bigWinAnimationGroup = new AnimationGroup();
      const bigWinAnimation = this.createBigWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.BigWin);

      const bigWinBgmChain = new AnimationChain();
      bigWinAnimation.addOnStart(() => {
        AudioApi.fadeOut(500, getBGMSoundByGameMode(Logic.the.controller.gameMode));
      });

      bigWinAnimationGroup.addAnimation(bigWinAnimation);
      bigWinAnimationGroup.addAnimation(bigWinBgmChain);
      animationChain.appendAnimation(bigWinAnimationGroup);
    }
    if (stage >= WinStages.MegaWin) {
      const megaWinAnimation = this.createMegaWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.MegaWin);
      animationChain.appendAnimation(megaWinAnimation);
    }
    if (stage >= WinStages.SuperWin) {
      const superWinAnimation = this.createSuperWinAnimation(
        normalizedWinAmount,
        betAmount,
        stage === WinStages.SuperWin,
      );
      animationChain.appendAnimation(superWinAnimation);
    }
    if (stage >= WinStages.UltraWin) {
      const ultraWinAnimation = this.createUltraWinAnimation(
        normalizedWinAmount,
        betAmount,
        stage === WinStages.UltraWin,
      );
      animationChain.appendAnimation(ultraWinAnimation);
    }

    const stayAnimation = Tween.createDelayAnimation(8000);
    animationChain.appendAnimation(stayAnimation);

    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValue,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: mappedAudioSprites[ISongs.BigWin_End].duration,
    });

    fadeOutAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      if (stage >= WinStages.BigWin) {
        if (stage === WinStages.BigWin) this.endBigWin();
        if (stage === WinStages.MegaWin) this.endMegaWin();
        if (stage === WinStages.SuperWin) this.endSuperWin();
        if (stage === WinStages.UltraWin) this.endUltraWin();
        this.background.visible = false;
        setIsDuringBigWinLoop(false);
        AudioApi.stop({ type: ISongs.BigWin });
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
      }
    });
    fadeOutAnimation.addOnSkip(() => {
      this.spine.state.setEmptyAnimation(0, 0);
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    fadeOutAnimation.addOnComplete(() => {
      this.spine.state.setEmptyAnimation(0, 0);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.bigWinCountUpAnimation = animationChain;
    animationChain.start();
  }

  private createBigWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * GAME_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startBigWin();
      AudioApi.play({ type: ISongs.BigWin, stopPrev: true });
      AudioApi.play({ type: ISongs.BigWin_Loop, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endBigWin();
        AudioApi.stop({ type: ISongs.BigWin });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endBigWin();
        AudioApi.stop({ type: ISongs.BigWin });
      }
    });
    return countUpAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * GAME_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startMegaWin();
      AudioApi.play({ type: ISongs.MegaWin, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endMegaWin();
        AudioApi.stop({ type: ISongs.MegaWin });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endMegaWin();
        AudioApi.stop({ type: ISongs.MegaWin });
      }
    });
    return countUpAnimation;
  }

  private createSuperWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (Math.min(win / bet, SUPER_WIN_AMOUNT_LIMIT) / (SUPER_WIN_COUNT_UP_MULTIPLIER * GAME_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * SUPER_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startSuperWin();
      this.setWinValue(Math.min(win, bet * SUPER_WIN_AMOUNT_LIMIT));
      AudioApi.play({ type: ISongs.SuperWin, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * SUPER_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endSuperWin();
        AudioApi.stop({ type: ISongs.SuperWin });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * SUPER_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endSuperWin();
        AudioApi.stop({ type: ISongs.SuperWin });
      }
    });
    return countUpAnimation;
  }

  private createUltraWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (win / bet / (ULTRA_WIN_COUNT_UP_MULTIPLIER * GAME_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * SUPER_WIN_AMOUNT_LIMIT,
      target: win,
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startUltraWin();
      AudioApi.play({ type: ISongs.UltraWin });
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endUltraWin();
        AudioApi.stop({ type: ISongs.UltraWin });
      }
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endUltraWin();
        AudioApi.stop({ type: ISongs.UltraWin });
      }
    });

    return countUpAnimation;
  }

  private clean(): void {
    setIsDuringBigWinLoop(false);
    AudioApi.stop({ type: ISongs.BigWin_Loop });
    AudioApi.stop({ type: ISongs.BigWin_End });
    AudioApi.stop({ type: ISongs.BigWin });
    AudioApi.stop({ type: ISongs.MegaWin });
    AudioApi.stop({ type: ISongs.SuperWin });
    AudioApi.stop({ type: ISongs.UltraWin });
    AudioApi.fadeIn(3000, getBGMSoundByGameMode(Logic.the.controller.gameMode));
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.setWinValue(0);
    this.bigWinCountUpAnimation = null;
  }

  public setWinValue(winValue: number): void {
    this.winValue.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue.style = BigWinTextValueTextStyle;
    this.winValue.anchor.set(0.5, 0.5);
    this.winValue2.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue2.style = BigWinTextValueTextStyle2;
    this.winValue2.anchor.set(0.5, 0.5);
  }

  resize(width: number, height: number) {
    this.background.width = width;
    this.background.height = height;
    this.isLandscape = width >= height;
    this.position.set(width / 2, height / 2);
    const factor = this.isLandscape ? height / 1080 : width / 1000;
    this.spine.scale.set(factor);
  }
}
