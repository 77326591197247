import { Group, Layer } from '@pixi/layers';
import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';
import { MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, ISettledBet, Payline } from '../../global.d';
import { setCurrentIsTurboSpin, setIsAutoSpins } from '../../gql/cache';
import { nextTick } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager, REEL_WIDTH, REELS_AMOUNT, SLOT_HEIGHT, SLOT_SCALE } from '../config';
import type { Icon } from '../d';

export class WinSlotsPresentation extends ViewContainer {
  private reelSpinOrderMap: { [x: string]: number } = {
    '1': 3,
    '0': 0,
    '2': 6,
    '3': 9,
    '4': 12,
    '5': 1,
    '6': 4,
    '7': 7,
    '8': 10,
    '9': 13,
    '10': 2,
    '11': 5,
    '12': 8,
    '13': 11,
    '14': 14,
  };

  private allSlotsHighlight: AnimationChain | null = null;

  private animationsArr: Spine[] = [];

  private loopAnimation: Animation | null = null;

  public layer: Layer;

  public layersGroup: Group;

  constructor() {
    super();
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = layer.name === 'moneySpine' ? 100 : 200;
    });
    this.layer = new Layer(this.layersGroup);
    this.addChild(this.layer);
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_ANIMATION, this.skipWinSlotsAnimation.bind(this));
  }

  private skipWinSlotsAnimation(): void {
    this.destroySpineWinAnimations();
    this.allSlotsHighlight?.skip();
    this.loopAnimation?.skip();
  }

  private destroySpineWinAnimations(): void {
    if (this.animationsArr.length) {
      this.animationsArr.forEach((spine) => {
        spine?.skeleton?.setToSetupPose();
        if (spine.state) {
          spine.state.tracks = [];
          nextTick(() => spine.destroy());
        }
      });
      this.animationsArr = [];
    }
  }

  public showWin(betResult: ISettledBet, paylinesData: Payline[]): void {
    this.animationsArr = [];
    const paylines = paylinesData;
    const { spinResult } = betResult.bet.result;
    const currentSpinResult = [...spinResult];
    const paylinesPositions = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        paylinesPositions.add(position);
      });
    });
    const slots = Array.from(paylinesPositions).sort((a, b) => a - b);
    this.allSlotsHighlight = this.highlightSlots(slots, spinResult);
    const delayToStartNextAnimation = Tween.createDelayAnimation(setCurrentIsTurboSpin() ? 250 : 500);
    delayToStartNextAnimation.addOnComplete(() => {
      this.destroySpineWinAnimations();
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
      if (!setIsAutoSpins()) this.loopAnimation?.start();
      if (setIsAutoSpins()) eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    this.allSlotsHighlight.appendAnimation(delayToStartNextAnimation);
    this.allSlotsHighlight.addOnStart(() => {
      if (paylines.some((payline) => payline.lineId !== null)) {
        eventManager.emit(EventTypes.SHOW_TINT, true);
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
      }
    });
    this.allSlotsHighlight.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
    });
    this.loopAnimation = this.createWinLineAnimation(currentSpinResult, paylines, true);

    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });

    this.allSlotsHighlight.start();
  }

  public createSlotSpineAnimation(id: number, srcName: string, animationName: string): Animation {
    const dummy = Tween.createDelayAnimation(1666);

    dummy.addOnStart(() => {
      const animation = new Spine(Loader.shared.resources[srcName as string]!.spineData!);
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (id % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      animation.position.set(x, y);
      animation.scale.set(SLOT_SCALE);

      this.addChild(animation);

      animation.state.setAnimation(0, animationName, false);
      this.animationsArr.push(animation);
    });

    return dummy;
  }

  public createWinLineAnimation(spinResult: Icon[], paylines: Payline[], isLoop: boolean): Animation {
    const isTurboSpin = setCurrentIsTurboSpin();
    const chain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const animationGroup = new AnimationGroup();
      payline.winPositions.forEach((slotId) => {
        const symbolId = spinResult[slotId as number]!.id;
        animationGroup.addAnimation(this.createSlotSpineAnimation(slotId, 'symbols', 'Frame_Win'));
        animationGroup.addAnimation(
          this.createSlotSpineAnimation(
            slotId,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
          ),
        );
      });
      animationGroup.addOnStart(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, false);
        if (payline.lineId !== null) {
          // eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        }
      });
      animationGroup.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      animationGroup.addOnSkip(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      const delayToStartNextAnimation = Tween.createDelayAnimation(isTurboSpin ? 250 : 500);
      delayToStartNextAnimation.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      delayToStartNextAnimation.addOnSkip(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      chain.appendAnimation(animationGroup);
      chain.appendAnimation(delayToStartNextAnimation);
    });
    return chain;
  }

  public highlightSlots(slots: number[], spinResult: Icon[]): AnimationChain {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId, _index) => {
      const symbolId = spinResult[slotId as number]!.id;
      animationGroup.addAnimation(this.createSlotSpineAnimation(slotId, 'symbols', 'Frame_Win'));
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          slotId,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
        ),
      );
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    chain.appendAnimation(animationGroup);
    return chain;
  }
}
