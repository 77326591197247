import type React from 'react';
import { useTranslation } from 'react-i18next';
import { ResourceTypes } from '../../../resources.d';
import Resources from '../../../utils/resources';
import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['payLines']}>
        <h1 className={styles['title']}>{t('infoPayLinesTitle')}</h1>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoPaylines)} alt="payLines" />
      </div>
    </div>
  );
};

export default PayLines;
