import React, { useCallback, useEffect } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { config, ISongs } from '../../../config';
import { EventTypes, GameMode, ISettledBet } from '../../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetResultDuration,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentIsTurboSpin,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsPopupOpened,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setUserBalance,
  setWinAmount,
} from '../../../gql/cache';
import type { IConfig, ISlotConfig, IStressful } from '../../../gql/d';
import { placeBetGql } from '../../../gql/mutation';
import {
  getAutoSpinsGql,
  getBetAmountGql,
  getSlotLoadProgressInfoGql,
  isStoppedGql,
  slotConfigGql,
  stressfulGql,
} from '../../../gql/query';
import { Logic } from '../../../logic';
import { eventManager } from '../../../slotMachine/config';
import { canPressSpin, showCurrency } from '../../../utils';
import type { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(slotConfigGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSets } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const lineSet = lineSets[0];
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const balanceAmount = userBalance?.balance.amount || 0;

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError() {
      eventManager.emit('placeBetCompleted');
    },

    async onCompleted({ placeBet }) {
      eventManager.emit('placeBetCompleted');
      setCurrentIsTurboSpin(setIsTurboSpin());
      Logic.the.controller.setResult(placeBet);
      if (Logic.the.isStopped()) {
        Logic.the.quickStop();
      }
      setBetResultDuration(performance.now() - setBetResultDuration());
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      setBetResultDuration(performance.now());

      clearTimeout(timeout);
      if (AudioApi.isRestricted) {
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: [{ type: ISongs.Background }],
        });
      }
      if (Logic.the.isReadyToStop()) {
        Logic.the.quickStop();
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
        return;
      }
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      if (Logic.the.isReadyToSkip()) {
        Logic.the.skipWinAnimation();
        return;
      }
      if (Logic.the.isReadyToSpin()) {
        resetPopupsStateToClosed();
        setCurrentIsTurboSpin(isTurboSpin);
        setWinAmount(0);
        setLastRegularWinAmount(0);
        Logic.the.spin();
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSet!.id,
              userBonusId: setCurrentBonusId() as string,
            },
          },
        });
        setCurrentBonusId(undefined);
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.play({ type: ISongs.UI_SpinStart, stopImmediately: [ISongs.UI_ButtonClose] });
        if (AudioApi.isRestricted || AudioApi.isSuspended) {
          AudioApi.unSuspend();
          AudioApi.processRestriction({
            restricted: false,
            listToPlay: [{ type: ISongs.Background }],
          });
        }
      }
    },
    [dataBet?.betAmount, fnGet, lineSet, lineSet, slotId],
  );

  useEffect(() => {
    const freeSpin = () => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      setBetResultDuration(performance.now());
      setCurrentIsTurboSpin(setIsTurboSpin());
      clearTimeout(timeout);
      Logic.the.spin();
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSet!.id,
            userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.UI_SpinStart });
    };

    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();
      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      if (autoSpinsLeft || bonus || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        Logic.the.controller.gameMode === GameMode.BUY_FEATURE) &&
      setIsFreeSpinsWin()
    ) {
      return;
    }
    onSpin(isTurboSpin);
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();
        eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

        if (
          !canPressSpin({
            gameMode: Logic.the.controller.gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isPopupOpened: setIsPopupOpened(),
            isAutoPlay: false,
          })
        ) {
          return;
        }

        if (isAutoSpins) {
          Logic.the.skipWinAnimation();
          if (Logic.the.isReadyToStop()) {
            Logic.the.quickStop();
            client.writeQuery({
              query: isStoppedGql,
              data: {
                isSlotStopped: true,
              },
            });
          }
          return;
        }

        onSpin(isTurboSpin);
      }
    },
    [
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return <></>;
};

export default Spin;
